<template>
  <div className="animated fadeIn">
    <i class="icon-cui-graph mr-1"></i> Marketing Overview<hr />
    <div class="text-center mb-2">
      <b-btn variant="link" @click="setReportType('source')">Source</b-btn> | 
      <b-btn variant="link" @click="setReportType('sport')">Sports</b-btn> | 
      <b-btn variant="link" @click="setReportType('event')">Events</b-btn>
    </div>
    <h4 class="text-center">
      <b-btn @click="decreaseStartDate()"><i class="fa fa-arrow-left" size="sm"></i></b-btn>
        &nbsp;<a href="javascript:;" @click="resetStartDate()">{{ currentSubject }} Overview</a>&nbsp;
      <b-btn @click="increaseStartDate()"><i class="fa fa-arrow-right" size="sm"></i></b-btn>
    </h4> 
    <div class="row justify-content-center">
      <div class="col-auto">
        <component :is="currentComponent" :months="months" :startDate="startDate" :userRole="userRole"></component>
      </div>
    </div>
  </div>
</template>

<script>
import { constants } from '@/shared/constants'
import daterangepicker from 'daterangepicker'
require('daterangepicker/daterangepicker.css')
import MarketingOverviewSource from '@/components/MarketingOverviewSource'
import MarketingOverviewSport from '@/components/MarketingOverviewSport'

export default {
  props: {
    userRole: {
      type: String,
      default: null
    },
    months: {
      type: Number,
      default: () => 3
    },
    iniStartDate: {
      default () {
        return this.$moment().format('YYYY-MM-DD')
      }
    }
  },
  data () {
    return {
      startDate: this.iniStartDate,
      currentComponent: 'MarketingOverviewSource',
      currentSubject: 'Sources'
    }
  },
  methods: {
    decreaseStartDate () {
      this.startDate = this.$moment(this.startDate).subtract(this.months, 'M').format()
    },
    setReportType (type) {
      if (type === 'source') this.currentComponent = 'MarketingOverviewSource'
      else if (type === 'sport') this.currentComponent = 'MarketingOverviewSport'
      else alert('this feature is not complete yet')
    },
    resetStartDate () {
      this.startDate = this.iniStartDate
    },
    increaseStartDate () {
      this.startDate = this.$moment(this.startDate).add(this.months, 'M').format()
    }
  },
  watch: {
    currentComponent (val) {
      switch (val) {
        case 'MarketingOverviewSource': this.currentSubject = 'Sources'; break
        case 'MarketingOverviewSport': this.currentSubject = 'Sports'; break
        case 'MarketingOverviewEvent': this.currentSubject = 'Events'; break
      }
    }
  },
  components: { MarketingOverviewSource, MarketingOverviewSport }
}
</script>

<style lang="scss">
th, .source-label {
  white-space: nowrap;
  font-weight: bold;
}
.divider {
  font-weight: bold;
  font-size: 1.2rem;
  color: royalblue;
}
</style>
