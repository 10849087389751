<template>
  <table v-if="show" id="table-marketing-stats" class="animated fadeIn fadeOut table table-hover table-responsive">
    <thead>
      <tr>
        <th></th>
        <template v-for="(data, month, index) in sourceConversions">
        <th v-if="'total' !== month" class="text-center" :key="index">{{ $moment(month).format('MM/YYYY') }}</th>
        </template>
        <th class="text-center">Total</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="source-label">New Members</td>
        <template v-for="(data, month, index) in sourceConversions">
        <td class="text-center" :key="index">{{ sourceConversions[month].total || '0' }}</td>
        </template>
      </tr>
      <tr v-for="(source, index) in marketingSources" :key="index">
        <td class="source-label">{{ source.name }}</td>
        <template v-for="(data, month, index2) in sourceConversions">
        <td v-if="'total' !== month" class="text-center" :key="index2">{{  data[source.id] || '0' }}</td>
        </template>
        <td class="text-center">{{  sourceConversions.total[source.id] || '0' }}</td>
      </tr>
      <tr>
        <td class="source-label">No Source</td>
        <template v-for="(data, month, index) in sourceConversions">
        <td v-if="'total' !== month" class="text-center" :key="index">{{  data.nosource || '0' }}</td>
        </template>
        <td class="text-center">{{  sourceConversions.total.nosource || '0' }}</td>
      </tr>
    </tbody>
  </table>
  <div v-else><b-spinner></b-spinner></div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { constants } from '@/shared/constants'
import daterangepicker from 'daterangepicker'
require('daterangepicker/daterangepicker.css')

const DT_FORMAT = 'YYYY-MM-DD'

export default {
  name: 'MarketingOverviewSource',
  props: {
    userRole: {
      type: String,
      default: null
    },
    months: {
      type: Number,
      default: () => 6
    },
    startDate: {
      default: () => Date.now()
    }
  },
  data: function () {
    return {
      sources: [],
      sourceConversions: {},
      sourcesLoaded: false,
      show: false
    }
  },
  computed: {
    ...mapGetters([
      'business',
      'marketingSources'
    ]),
    reportStartMonth () {
      return this.$moment(this.startDate).subtract((this.months-1), 'M').startOf('month').format(DT_FORMAT)
    },
    reportEndMonth () {
      return this.$moment(this.startDate).format(DT_FORMAT)
    }
  },
  methods: {
    ...mapActions([
      'getUsers',
      'getMarketingSources'
    ]),
    iniSourceConversions () {
      this.show = false
      this.sourceConversions = {}
      let dt = this.reportStartMonth
      for (let i = 0; i < this.months; i++) {
        this.sourceConversions[this.$moment(dt).format(DT_FORMAT)] = {}
        dt = this.$moment(dt).add(1, 'M').format(DT_FORMAT)
      }
      this.sourceConversions.total = {}
      this.sourceConversions.total.nosource = 0
      this.sourceConversions.total.total = 0
      this.doGetConversions()
    },
    setReportType (type) {
      if (type !== 'source')
      alert('this feature is not complete yet')
    },
    async doGetConversions () {
      let query = {
        businesses: this.business.id,
        roles: constants.ROLE_MEMBER,
        pagination: false,
        'created[after]': this.$moment(this.reportStartMonth).startOf('month').format(),
        'created[strictly_before]': this.$moment(this.reportEndMonth).add(1, 'M').startOf('month').format(DT_FORMAT)
      }
      await this.getUsers(query).then(resp => {
        let dt
        for (let i in resp) {
          dt = this.$moment(resp[i].created).startOf('month').format(DT_FORMAT)
          if (dt in this.sourceConversions) {
            if (!('total' in this.sourceConversions[dt])) this.sourceConversions[dt].total = 0
            if (resp[i].source) {
              if (!(resp[i].source.id in this.sourceConversions.total)) this.sourceConversions.total[resp[i].source.id] = 0
              if (!(resp[i].source.id in this.sourceConversions[dt])) this.sourceConversions[dt][resp[i].source.id] = 0
              this.sourceConversions.total[resp[i].source.id]++
              this.sourceConversions[dt][resp[i].source.id]++
            } else {
              if (!('nosource' in this.sourceConversions[dt])) this.sourceConversions[dt].nosource = 0
              this.sourceConversions.total.nosource++
              this.sourceConversions[dt].nosource++
            }
            this.sourceConversions[dt].total++
            this.sourceConversions.total.total++
          }
        }
      })
      this.show = true
    }
  },
  watch: {
    startDate () {
      this.iniSourceConversions()
    }
  },
  async mounted () {
    await this.getMarketingSources()
    this.iniSourceConversions()
  },
  destroyed () {
  }
}
</script>

<style lang="scss">
th, .source-label {
  white-space: nowrap;
  font-weight: bold;
}
.divider {
  font-weight: bold;
  font-size: 1.2rem;
  color: royalblue;
}
</style>
