<template>
  <table v-if="show" id="table-marketing-stats" class="animated fadeIn fadeOut table table-hover table-responsive">
    <thead>
      <tr>
        <th></th>
        <template v-for="(data, month, index) in sportConversions">
        <th v-if="'total' !== month" class="text-center" :key="index">{{ $moment(month).format('MM/YYYY') }}</th>
        </template>
        <th class="text-center">Total</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(sport, index) in franchiseSports" :key="index">
        <td class="source-label">{{ sport.label }}</td>
        <template v-for="(data, month, index2) in sportConversions">
        <td v-if="'total' !== month" class="text-center" :key="index2">{{  data[sport.id] || '0' }}</td>
        </template>
        <td class="text-center">{{  sportConversions.total[sport.id] || '0' }}</td>
      </tr>
    </tbody>
  </table>
  <div v-else><b-spinner></b-spinner></div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { constants } from '@/shared/constants'
import daterangepicker from 'daterangepicker'
require('daterangepicker/daterangepicker.css')

const DT_FORMAT = 'YYYY-MM-DD'

export default {
  name: 'MarketingOverviewSport',
  props: {
    userRole: {
      type: String,
      default: null
    },
    months: {
      type: Number,
      default: () => 6
    },
    startDate: {
      default: () => Date.now()
    }
  },
  data: function () {
    return {
      sources: [],
      sportConversions: {},
      sourcesLoaded: false,
      show: false
    }
  },
  computed: {
    ...mapGetters([
      'business',
      'eventRegistrations',
      'sports'
    ]),
    franchiseSports () {
      return this.sports.filter((e) => (this.business.sports.includes(e['@id'])))
    },
    reportStartMonth () {
      return this.$moment(this.startDate).subtract((this.months-1), 'M').startOf('month').format(DT_FORMAT)
    },
    reportEndMonth () {
      return this.$moment(this.startDate).format(DT_FORMAT)
    }
  },
  methods: {
    ...mapActions([
      'getUsers',
      'getSports',
      'getEventRegistrations'
    ]),
    iniSportConversions () {
      this.show = false
      this.sportConversions = {}
      let dt = this.reportStartMonth
      for (let i = 0; i < this.months; i++) {
        this.sportConversions[this.$moment(dt).format(DT_FORMAT)] = {}
        dt = this.$moment(dt).add(1, 'M').format(DT_FORMAT)
      }
      this.sportConversions.total = {}
      this.sportConversions.total.total = 0
      this.doGetConversions()
    },
    async doGetConversions () {
      let query = {
        business: this.business.id,
        pagination: false,
        'datetime[after]': this.$moment(this.reportStartMonth).startOf('month').format(DT_FORMAT),
        'datetime[strictly_before]': this.$moment(this.reportEndMonth).add(1, 'M').startOf('month').format(DT_FORMAT)
      }
      await this.getEventRegistrations(query).then(resp => {
        let dt
        for (let i in resp) {
          dt = this.$moment(resp[i].datetime).startOf('month').format(DT_FORMAT)
          if (dt in this.sportConversions) {
            if (!('total' in this.sportConversions[dt])) this.sportConversions[dt].total = 0
            if (resp[i].event.sport) {
              if (!(resp[i].event.sport.id in this.sportConversions.total)) this.sportConversions.total[resp[i].event.sport.id] = 0
              if (!(resp[i].event.sport.id in this.sportConversions[dt])) this.sportConversions[dt][resp[i].event.sport.id] = 0
              this.sportConversions.total[resp[i].event.sport.id]++
              this.sportConversions[dt][resp[i].event.sport.id]++
            }
            this.sportConversions[dt].total++
            this.sportConversions.total.total++
          }
        }
      })
      this.show = true
    }
  },
  watch: {
    startDate () {
      this.iniSportConversions()
    }
  },
  async mounted () {
    await this.getSports()
    this.iniSportConversions()
  },
  destroyed () {
  }
}
</script>

<style lang="scss">
th, .source-label {
  white-space: nowrap;
  font-weight: bold;
}
.divider {
  font-weight: bold;
  font-size: 1.2rem;
  color: royalblue;
}
</style>
